@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  * {
    @apply box-border select-none transition-all;
  }
  body {
    touch-action: pan-x pan-y;
    @apply text-gray-800;
  }

  .hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll::-webkit-scrollbar {
    @apply hidden;
  }
}

@layer components {
  .markdown a {
    @apply !text-blue-600;
  }

  .show-box {
    height: calc-size(auto);
    
  }

  .markdown img {
    @apply !hidden;
  }

  textarea {
    @apply resize-none border-none !outline-none !ring-0 active:!outline-none;
  }

  input,
  .input,
  select {
    @apply !outline-none !ring-0 active:!outline-none;
  }

  button {
    @apply rounded-lg border px-3 py-0.5 text-[15px] font-semibold active:scale-[.95];
  }

  .dialog {
    @apply w-[30rem] max-w-[95dvw] rounded-xl border bg-white p-5 drop-shadow-[100px_100px_150px_rgba(0,0,0,0.6)];
  }

  img,
  Image {
    @apply select-none;
  }

  .btn {
    @apply rounded-lg font-normal [@media_(max-width:412px)]:!px-3 [@media_(max-width:412px)]:!text-[15px];
  }

  .text-shade {
    @apply text-gray-500;
  }

  .text-shade-0 {
    @apply text-gray-400;
  }

  .tab-active {
    @apply !rounded-2xl;
  }

  .modal {
    @apply bg-teal-50;

    .modal-box {
      @apply border;
    }
  }

  .textmark-indonesia {
    @apply bg-[linear-gradient(180deg,_#C8102E_50%,_#A0AEC0_50%)] bg-clip-text !text-transparent;
  }

  .textmark-rainbow {
    @apply bg-gradient-to-t from-yellow-500 via-purple-500 to-blue-500 bg-clip-text !text-transparent;
  }

  .clean {
    @apply !rounded-none !border-none !p-0;
  }
}

@layer utilities {
  :root {
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsla(210, 18%, 87%, 1);
  }
  .markdown {
    table {
      border-spacing: 0;
      border-collapse: collapse;
      display: block;
      margin-top: 0;
      margin-bottom: 16px;
      width: max-content;
      max-width: 100%;
      overflow: auto;
    }

    tr {
      background-color: var(--color-canvas-default);
      border-top: 1px solid var(--color-border-muted);
    }

    tr:nth-child(2n) {
      background-color: var(--color-canvas-subtle);
    }

    td,
    th {
      padding: 6px 13px;
      border: 1px solid var(--color-border-default);
    }

    th {
      font-weight: 600;
    }

    table img {
      background-color: transparent;
    }

    /* Style the footnotes section. */
    .footnotes {
      font-size: smaller;
      color: #8b949e;
      border-top: 1px solid #30363d;
    }

    /* Hide the section label for visual users. */
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      word-wrap: normal;
      border: 0;
    }

    /* Place `[` and `]` around footnote references. */
    [data-footnote-ref]::before {
      content: '[';
    }

    [data-footnote-ref]::after {
      content: ']';
    }

    ul {
      list-style-type: disc;
      padding-left: 2em;
    }
    ol {
      list-style-type: decimal;
      padding-left: 2em;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-left: 0;
      padding-left: 1em;
    }
  }
}
